import {lazyImg} from "./class/lazyImg";

window.each = (arr, fn) => {
  let i = 0, l = arr.length;
  if (!Array.isArray(arr)) return eachObj(arr, fn);
  for (i; i < l; i++) {
    fn(i, arr[i]);
  }
};

window.eachObj = (obj, fn) => {
  if (typeof obj !== 'object') return;
  let key;
  for (key of Object.keys(obj)) fn(key, obj[key]);
};

window.addCSS = (src, cfg = {id: false, cb: false}) => {
  if (cfg.id && document.head.querySelector(`link[data-id="${cfg.id}"]`)) return cfg.cb && cfg.cb();
  let el;

  el = document.createElement('link');
  if (cfg.id) el.dataset.id = cfg.id;
  el.rel = 'stylesheet';
  el.href = src;

  document.head.appendChild(el);
  cfg.cb && el.addEventListener('load', cfg.cb);
  return el;
};

window.loadlib = (src, lib, cb) => {
  if (typeof cb !== 'function' || !src || !lib) return;
  return typeof window[lib] === 'undefined' ? addJS(src, {id: lib, cb: cb}) : cb();
};

window.addJS = (src, cfg = {id: false, cb: false}) => {
  if (cfg.id && document.body.getElementsByClassName(cfg.id)[0]) return cfg.cb();
  let el;

  el = document.createElement('script');
  cfg.id && el.classList.add(cfg.id);
  el.async = cfg.async || false;
  el.defer = cfg.defer || false;
  el.src = src;

  document.body.appendChild(el);
  cfg.cb && el.addEventListener('load', cfg.cb, false);
};

window.prepend = (relEl, el) => {
  return relEl.firstChild ? relEl.insertBefore(el, relEl.firstChild) : relEl.appendChild(el);
};

window.get_lang = () => {
  return window.navigator.language.toLowerCase().substring(0, 2);
}

/*export async function _request(url, cfg = {}) {
  let controller = new AbortController(), obj = Object.assign({
    signal: controller.signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json' //'Content-Type': 'application/json', multipart/form-data, application/json, application/x-www-form-urlencoded
    },
  }, cfg)

  obj.headers['Content-Type'] === 'multipart/form-data' && delete(obj.headers['Content-Type'])

  const response = await fetch(url, obj)
  response.status !== 200 && controller.abort()

  switch(obj.type) {
    case 'text':
      return response.text()
    default:
      return response.json()
  }
}*/

/*
window.loadFont = (url) => {
  let xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      let css = xhr.responseText, head = document.getElementsByTagName('head')[0], style = document.createElement('style');
      css = css.replace(/}/g, 'font-display:swap;}');
      style.appendChild(document.createTextNode(css));
      head.appendChild(style);
      head = null;
      style = null;
    }
  };
  xhr.send();
};
*/

/*window.loadCSS = () => {
  let loadDeferredStyles = () => {
    const addStylesNode = document.getElementById('defer');
    if (!addStylesNode) return;

    const replacement = document.createElement('div');
    replacement.innerHTML = addStylesNode.textContent;
    document.body.appendChild(replacement);
    addStylesNode.parentElement.removeChild(addStylesNode);
  };

  const raf = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
  if (raf) raf(function () {
    window.setTimeout(loadDeferredStyles, 0);
  });
  else window.addEventListener('load', loadDeferredStyles);
};*/

window.is_fetch = () => {
  return 'signal' in new Request('');
};

window.is_observer = () => {
  return ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window) ? true : false;
};

/*window.get_csv = (els, size, filename = 'raport') => {
  let csvContent = "data:text/csv;charset=utf-8,", rows = document.body.getElementsByClassName(els), rowsArr = [],
    arr = [], len = parseInt(size) || 1, i = 0;

  each(rows, (key, val) => {
    i++;
    let content = val.textContent;
    arr.push(content.replace(/\,/g,''));
    if (i === len) {
      rowsArr.push(arr);
      i = 0;
      arr = [];
    }
  });

  each(rowsArr, (key, val) => {
    let row = val.join(",");
    csvContent += row + "\r\n";
  });

  const encodedUri = encodeURI(csvContent);
  let link = document.createElement('a');

  let utc = new Date().toJSON().slice(0, 10);

  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${filename}_${utc}.csv`);
  document.body.appendChild(link);
  link.click();
  del(link);
};*/

/*window.dateToUnix = (val) => {
  return Math.floor(new Date(val).getTime() / 1000);
};*/

/*window.prerender = (relEl, tpl, cfg = {parse}) => {
  if (!relEl) return;
  let child;
  const parse = typeof cfg.parse === 'undefined' ? true : cfg.parse;

  if (tpl.nodeName === 'TEMPLATE') {
    child = document.importNode(tpl.content, true);
  } else if (typeof tpl === 'string') {
    const range = document.createRange();
    range.selectNode(relEl);
    child = range.createContextualFragment(tpl);
  } else {
    child = tpl;
  }
    prepend(relEl, child);
    return relEl.firstElementChild;
};*/

window.render = (relEl, tpl, cfg = {}) => {
  if (!relEl) return;
  let child, config = Object.assign({
    auto: true,
    clear: false
  }, cfg);

  if (tpl.nodeName === 'TEMPLATE') {
    child = document.importNode(tpl.content, true);
  } else if (typeof tpl === 'string') {
    const range = document.createRange();
    range.selectNode(relEl);
    child = range.createContextualFragment(tpl);
  } else {
    child = tpl;
  }

  if(!config.auto) return child;
  config.clear && clearDom(relEl)
  relEl.appendChild(child);
  new lazyImg(relEl.lastElementChild.querySelectorAll('[data-url]'))
  typeof config.cb === 'function' && config.cb(relEl.lastElementChild)
  return relEl.lastElementChild;
}

window.is_touch = () => {
  return 'ontouchstart' in document.documentElement;
}

/*window.is_int = (value) => {
  if (isNaN(value)) return false;
  let x = parseFloat(value);
  return (x | 0) === x;
};*/

/*window.is_str = (val) => {
  return (Object.prototype.toString.call(val) === '[object String]');
};*/

/*window.ms_time = (ms) => {
  let s = Math.floor(ms % 60), m = Math.floor(ms / 60);
  if (s < 10) s = '0' + String(s);
  return ('0' + m).substr(-2) + ':' + ('0' + s).substr(-2);
};*/

/*window.ms_time = (input) => {
  let years = 0, days = 0, hours = 0, minutes = 0, seconds = 0;
  let ref = [31536000, 86400, 3600, 60, 1];
  let i = 0;
  for (i; i < ref.length; i++) {
    let val = ref[i];
    while (val <= input) {
      input -= val;
      if (i === 0) years++;
      if (i === 1) days++;
      if (i === 2) hours++;
      if (i === 3) minutes++;
      if (i === 4) seconds++;
    }
  }
  return {y: years, d: days, h: hours, m: minutes, s: seconds};
};*/

/*window.sec2time = (timeInSeconds) => {
  let pad = (num, size) => {
      return ('000' + num).slice(size * -1);
    },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-3);

  //return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + ':' + pad(milliseconds, 3);
  return pad(minutes, 2) + ':' + pad(seconds, 2);
};*/

/*window.copyToClip = (el) => {
  let oldContentEditable = el.contentEditable,
    oldReadOnly = el.readOnly,
    range = document.createRange();

  el.contentEditable = true;
  el.readOnly = false;
  range.selectNodeContents(el);

  const s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

  el.contentEditable = oldContentEditable;
  el.readOnly = oldReadOnly;

  document.execCommand('copy');
};*/

/*window.getOffset = (el, relEl) => {
  if (!el || el === relEl) return {l: 0, t: 0};
  let eOff = {l: el.offsetLeft, t: el.offsetTop},
    pOff = getOffset(el.offsetParent, relEl);
  return {l: eOff.l + pOff.l, t: eOff.t + pOff.t};
};*/

/**
 * Add an item to a local storage string
 * @param  {String} name      The localStorage() key
 * @param  {String} value     The localStorage() value
 * @param  {String} delimiter The delimiter to use to separate items
 */
/*window.addStorage = (name, value, delimiter = ',') => {
  let existing = localStorage.getItem(name),
    data = existing ? existing + delimiter + value : value;

  localStorage.setItem(name, data);
};*/

/**
 * Add an item to a localStorage() object
 * @param {String} name  The localStorage() key
 * @param {String} key   The localStorage() value object key
 * @param {String} value The localStorage() value object value
 */

/*window.addStorage = (name, key, value) => {
  let existing = localStorage.getItem(name);

  existing = existing ? JSON.parse(existing) : {};
  existing[key] = value;
  localStorage.setItem(name, JSON.stringify(existing));
};*/

/**
 * Add an item to a localStorage() array
 * @param {String} name  The localStorage() key
 * @param {String} value The localStorage() value
 */
/*window.addStorageArr = (name, value) => {
  let existing = localStorage.getItem(name);

  existing = existing ? existing.split(',') : [];
  existing.push(value);

  localStorage.setItem(name, existing.toString());
};*/

/*window.addToFav = (title, url) => {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("msie 8") > -1) {
    external.AddToFavoritesBar(url, title, '');
  } else {
    try {
      window.external.addFavorite(url, title);
    } catch (e) {
      try {
        window.sidebar.addPanel(title, url, '');
      } catch (e) {
        alert('Please try shortcut Ctrl+D to add to favorite');
      }
    }
  }
  return false;
};

window.pressKey = (e, key, method = 'keydown') => {
  e = new Event(method);
  e.key = key;
  e.keyCode = e.key.charCodeAt(0);
  e.which = e.keyCode;
  e.altKey = false;
  e.ctrlKey = false;
  e.shiftKey = false;
  e.metaKey = true;
  //e.bubbles = true; ?? o.0
  document.dispatchEvent(e);
};*/

/*window.gen_token = (length = 12) => {
  let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-', token = '', i = 0;
  for (i; i < length; i++) {
    token += chars[Math.floor(Math.random() * chars.length)];
  }
  return token;
};*/

window.getExt = (filename) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? "" : ext[1];
}

window.sc = (id, offset = 0) => {
  const el = typeof id === 'string' ? document.body.querySelector(id) : id;

  if (!el) return;

  let image = document.body.querySelectorAll('img[data-url]:not(.loaded)');
  image.length && each(image, (key, val) => {
    val.src = val.dataset.url;
  });

  image = null;

  loadlib('js/gsap.js', 'TweenLite', () => {
    TweenLite.to(window, 1, {scrollTo: {y: el, offsetY: offset, autoKill: true}, ease: Power3.easeInOut});
  })
};

/*window.hide = (el, time = 0.3, remove = false) => {
  el = typeof el === 'string' ? document.body.getElementsByClassName(el)[0] : el;
  return TweenLite.to(el, time, {
    autoAlpha: 0, onComplete: () => {
      if (remove) del(el);
    }
  })
};*/

/*window.show = (el, time = 0.3, cb) => {
  el = typeof el === 'string' ? document.body.getElementsByClassName(el)[0] : el;
  TweenLite.set(el, {autoAlpha: 0});
  return TweenLite.to(el, time, {
    autoAlpha: 1, onComplete: () => {
      if (cb) cb();
    }
  })
};*/

/*window.str_bold = (str, find) => {
  let word = str.toLowerCase(), find_lower = find.toLowerCase(), a = str[0], b = find[0], key_first = str.substr(0, find.length),key_last = str.slice(`-${find.length}`);
  if (word[0] === find_lower[0]) {
    return a === b.toUpperCase() ? str.replace(key_first, '<b>' + key_first + '</b>') : str.replace(find_lower, '<b>' + find_lower + '</b>');
  }

  if (word.slice(-1) === find_lower.slice(-1)) {
    return str.replace(key_last, '<b>' + key_last + '</b>');
  }

  return str;
};*/

/*window.clearFocus = (el = document.body) => {
  let tmp = document.createElement('input');

  el.appendChild(tmp);
  tmp.focus();
  el.removeChild(tmp);
};*/

/*window.set_timer = (callback, delay) => {
  let timerId, start, remaining = delay;

  this.pause = () => {
    clearTimeout(timerId);
    remaining -= new Date() - start;
  };

  this.resume = () => {
    start = new Date();
    clearTimeout(timerId);
    timerId = setTimeout(callback, remaining);
  };
  this.resume();
};*/

/*window.time = (date = new Date) => {
  return date / 1E3 | 0;
};*/

window.sanitize = (val) => {
  const map = {
    /*'&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    "/": '&#x2F;',*/
    '&': '',
    '<': '',
    '>': '',
    '"': '',
    "'": '',
    '$': '',
    '+': '',
    '{': '',
    '}': '',
    '`': '',
    '=': '',
    '?': '',
    '\(': ''
  };
  let re = /[(<>"'$+{}?=`&]/ig;
  return val.replace(re, (match) => (map[match]));
};

window.rand = (min = 1, max = 5) => {
  //return Math.round( Math.random() * (max - min) + min);
  return ~~(Math.random() * (max - min + 1)) + min;
};

window.randEx = (min, max, blacklist = []) => {
  //let rand = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
  let retv;
  while (blacklist.indexOf(retv = rand(min, max)) > -1) {
  }
  return retv;
};

window.shuffle = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

/*window.validateNip = (val) => {
  var nipWithoutDashes = val.replace(/-/g, ''),
    reg = /^[0-9]{10}$/;

  if (reg.test(nipWithoutDashes) === false) {
    return false;
  }
  else {
    var digits = ('' + nipWithoutDashes).split(''),
      checksum = (6 * parseInt(digits[0]) + 5 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 2 * parseInt(digits[3]) + 3 * parseInt(digits[4]) + 4 * parseInt(digits[5]) + 5 * parseInt(digits[6]) + 6 * parseInt(digits[7]) + 7 * parseInt(digits[8])) % 11;

    return (parseInt(digits[9]) === checksum);
  }
};*/

/*window.validateregon9 = (regon) => {
  var reg = /^[0-9]{9}$/;
  if (!reg.test(regon))
    return false;
  else {
    var digits = ("" + regon).split("");
    var checksum = (8 * parseInt(digits[0]) + 9 * parseInt(digits[1]) + 2 * parseInt(digits[2]) + 3 * parseInt(digits[3]) + 4 * parseInt(digits[4]) + 5 * parseInt(digits[5]) + 6 * parseInt(digits[6]) + 7 * parseInt(digits[7])) % 11;
    if (checksum == 10)
      checksum = 0;

    return (parseInt(digits[8]) == checksum);
  }
};*/

/*window.is_zip = (val) => {
  let postcodeVal = /^([0-9]{2})\-([0-9]{3})$/;
  return postcodeVal.test(val);
};*/

/*
// old fnc
window.validateNumber = (el) => {
  if (el.value.length !== 0) {
    let digits = /^[0-9]+$/;
    if (el.value.match(digits)) {
      return true;
    } else {
      el.value = '';
      return false;
    }
  }
};*/

window.is_num = (val) => {
  let re = /([0-9])$/;
  return re.test(val);
};

window.is_val = (val) => {
  let re = /([ 01234567890\-+AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż.])$/;
  return re.test(val);
};

window.is_letter = (val) => {
  let re = /([AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż])$/;
  return re.test(val);
};

window.is_email = (val) => {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;
  return re.test(val);
};

/*window.is_npwz = (el) => {
  let i = 0, npwzVal = el.value,
    //npwzVal = _this._npwz1.value + _this._npwz2.value + _this._npwz3.value + _this._npwz4.value + _this._npwz5.value + _this._npwz6.value + _this._npwz7.value,
    control_num = npwzVal.charAt(0),
    control_count_num = 0;

  //_this._npwz = npwzVal;

  for (i; i < 7; i++) control_count_num += npwzVal.charAt(i) * i;
  let final_control_num = control_count_num % 11;
  return npwzVal.length !== 7 || parseInt(npwzVal.charAt(0)) === 0 || (parseInt(final_control_num) !== parseInt(control_num)) ? false : true;
};*/

/*window.num_float_space = (x) => {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};*/

window.num_space = (num) => {
  try {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } catch (e) {

  }
};

window.replace_all = (str, find, replace) => {
  let i = 0;
  for (i; i < find.length; i++) str = str.replace(new RegExp(find[i], 'gi'), replace[i]);
  return str;
};

window.observe = (obj, callback, get_callback, del_callback) => {
  if (!obj || typeof callback !== 'function') return;

  let handler = {
    set(target, property, value) {
      if (target[property] !== value) {
        callback(property, value);
        target[property] = value;
        return true;
      }
    },
    get(target, property) {
      typeof get_callback === 'function' && get_callback(target, property);
      return target[property];
    }
  };

  if (typeof(Proxy) === 'function') {
    handler.deleteProperty = (target, property) => {
      delete target[property], typeof del_callback === 'function' && del_callback(target, property);
    };
    return new Proxy(obj, handler)
  }
  return Proxy_(obj, handler)
};


/*window.get_props = (obj) => {
  let objectToInspect, result = [];

  for(objectToInspect = obj; objectToInspect !== null;
      objectToInspect = Object.getPrototypeOf(objectToInspect)) {
    result = result.concat(
      Object.getOwnPropertyNames(objectToInspect)
    );
  }

  return result;
};*/

/*window.cleanHistory = (title = document.title, href = location.href) => {
  history.pushState(null, title, href);
};

window.updateHistory = (title = document.title, href = location.href) => {
  history.replaceState(null, title, href);
};

window.disableBack = (title = document.title, href = location.href) => {
  history.pushState(null, title, href);
  window.addEventListener('popstate', cleanHistory);
};

window.enableBack = (title = document.title, href = location.href) => {
  history.pushState(null, title, href);
  window.removeEventListener('popstate', cleanHistory);
};*/

/*window.isOS = (platform = 'Mac|iPhone|iPod|iPad') => {
  return !!navigator.platform.match(platform);
};

window.is_chrome = () => {
  return /chrome/i.test(navigator.userAgent);
};*/

/*window.is_ie = () => {
  let ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  let msie = ua.indexOf('MSIE ');
  /!*if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }*!/

  let trident = ua.indexOf('Trident/');
  /!*if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }*!/

  //let edge = ua.indexOf('Edge/');
  /!*if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }*!/

  if (msie > 0 || trident > 0) return true;

  // other browser
  return false;
};*/

/*window.is_pair = (num) => {
  return num % 2 === 0;
};*/

window.clearDom = (el) => {
  if (Array.isArray(el)) return clearAll(el);
  while (el.firstChild) {
    el.removeChild(el.firstChild);
  }
};

window.clearAll = (arr) => {
  each(arr, (key, val) => {
    clearDom(val);
  })
};

window.sendPost = function ({url, data, dataFile, method = 'POST', resp = 'text', header = 'application/x-www-form-urlencoded;charset=UTF-8', upload = false, fileType, bg = 'rgba(0,0,0,.8)', cb}) {
  const xhr = new XMLHttpRequest();

  xhr.open(method, url, true);
  xhr.responseType = resp;

  xhr.onerror = function () {
    console.error('There was a connection error of some sort');
  };

  xhr.onload = function () {
    if (this.status >= 200 && this.status < 400) {
      if (cb && typeof(cb) === 'function') xhr.callback = cb(this);
    }
  };

  if (upload) {
    let file = dataFile.files[0],
      formData = new FormData();

    if (fileType && isFileType(fileType, dataFile.value) === false) {
      mPop({
        id: 'mpop',
        msg: '<h5 class="red">BŁĄD</h5><p>Nieprawidłowy format pliku.</p>',
        bg: bg,
        btnClass: 'btn btn--error'
      });
      return;
    }

    formData.append(dataFile.name, file);

    xhr.send(formData);
    return;
  }

  xhr.setRequestHeader('Content-Type', header);
  xhr.send(data);
};

window.isRWD = (val) => {
  return window.innerWidth <= val ? true : false;
};

/*window.is_height = (val) => {
  let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return h <= val ? true : false;
};*/

/*window.addClass = (el, className) => {
  if (el.classList) {
    el.classList.add(className);
  } else {
    el.className += ' ' + className;
  }
};*/

/*window.removeClass = (el, className) => {
  if (el.classList) {
    el.classList.remove(className);
  } else {
    el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    // el.className = ( ' ' + el.className + ' ' ).replace( ( ' ' + className + ' ' ), ' ' ).trim();
  }
};*/

/*window.hasClass = (el, className) => {
  if (el.classList)
    return el.classList.contains(className);
  else
  // return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
    return ((el.className.split(' ')).indexOf(className) >= 0);
};*/

/*window.addCookie = (cname, cvalue, exdays = 1) => {
  let d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};*/

/*window.removeCookie = (cname) => {
  var expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
  document.cookie = cname + ";" + expires + ";path=/";
};*/

/*window.getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
};*/

/*window.getStyle = (el, prop) => {
  return window.document.defaultView.getComputedStyle(el).getPropertyValue(prop);
};*/

/*window.indexOf = (el) => {
  let i, nodes = el.parentElement.children;
  for (i = 0; i < nodes.length; i++) {
    if (nodes[i] === el) return i;
  }
  return -1;
};*/

/*window.inClass = (el, className) => {
  if (!el) return false;
  if (hasClass(el, className)) return true;
  return inClass(el.offsetParent, className);
};*/

/*window.doCallback = (cb) => {
  if (typeof cb === 'function') cb();
};*/

/*window.debug = (str) => {
  let debugBox = document.getElementById('debugBox');

  if (!debugBox) {
    debugBox = document.createElement('div');
    debugBox.id = 'debugBox';
    debugBox.style.zIndex = 2000;
    debugBox.style.position = 'absolute';
    debugBox.style.top = 0;
    debugBox.style.right = 0;
    debugBox.style.width = '320px';
    debugBox.style.height = '240px';
    debugBox.style.overflow = 'auto';
    debugBox.style.backgroundColor = '#000';
    debugBox.style.border = '1px solid #f00';
    debugBox.style.color = '#fff';
    debugBox.style.fontFamily = 'consolas';
    debugBox.style.fontSize = '12px';
    debugBox.style.lineHeight = '14px';

    document.body.appendChild(debugBox);
  }

  let debugLog = document.createElement('div');
  debugLog.style.padding = '2px 10px';
  debugLog.innerText = str;

  debugBox.appendChild(debugLog);
  debugBox.scrollTop = debugBox.scrollHeight;
};*/

window.setStyle = (el, propertyObject) => {
  let property;
  for (property in propertyObject) el.style[property] = propertyObject[property];
};

/*
window.gaInit = (id) => {
  addJS(`https://www.googletagmanager.com/gtag/js?id=UA-${id}`);
  window.dataLayer = window.dataLayer || [];
  window.gid = id;
  window.gtag = () => {
    dataLayer.push(arguments);
  };
  gtag('js', new Date());
  gtag('config', id);
};
*/

/*window.gaView = (id = gid, url = location.pathname, title = document.title) => {
  gtag('config', id, {
    'page_title': title,
    'page_path': url
  });
};

window.gaEvent = (ev = 'click', cat, label, val) => {
  gtag('event', ev, {
    'event_category': cat,
    'event_label': label,
    'value': val
  })
};*/

/*window.setEv = (name, cfg = {}) => {
  let ev = new CustomEvent(name, {detail: cfg});
  return {name: name, ev: ev};
};*/

window.fireEv = (el, name, cfg = {}) => {
  let ev = new CustomEvent(name, {detail: cfg});
  el.dispatchEvent(ev);
  return {el: el, name: name, ev: ev};
};

window.del = (el) => {
  if (!el || !el.parentNode) return;
  return el.parentNode.removeChild(el);
};

/*window.openFullScr = (el) => {
  if (el.requestFullscreen) {
    el.requestFullscreen();
  } else if (el.mozRequestFullScreen) {
    el.mozRequestFullScreen();
  } else if (el.webkitRequestFullscreen) {
    el.webkitRequestFullscreen();
  } else if (el.msRequestFullscreen) {
    el.msRequestFullscreen();
  }
};

window.toggleFullScr = (el) => {
  document.fullscreenEnabled = document.documentElement.requestFullscreen || document.documentElement.mozRequestFullScreen || document.documentElement.webkitRequestFullscreen || document.documentElement.msRequestFullscreen || document.requestFullscreen;
  if (!isFullScr()) return openFullScr(el);
};

window.isFullScr = () => {
  if (document.fullscreen) {
    document.exitFullscreen();
    return true;
  }
  else if (document.webkitIsFullScreen) {
    document.webkitExitFullscreen();
    return true;
  }
  else if (document.mozFullScreen) {
    document.mozCancelFullScreen();
    return true;
  }
  return false;
};*/

/*
window.initTouch = () => {
  let M = Math, abs = M.abs, max = M.max,
    ce, m, th = 20, t, sx, sy, ex, ey, cx, cy, dx, dy, l,
    f = {
      touchstart: (e) => {
        t = e.touches[0];
        sx = t.pageX;
        sy = t.pageY
      },
      touchmove: (e) => {
        m = 1;
        t = e.touches[0];
        ex = t.pageX;
        ey = t.pageY
      },
      touchend: (e) => {
        ce = document.createEvent('CustomEvent');
        ce.initCustomEvent(m ? (
          max(dx = abs(cx = ex - sx), dy = abs(cy = ey - sy)) > th ?
            dx > dy ? cx < 0 ? 'swl' : 'swr' : cy < 0 ? 'swu' : 'swd' : 'fc'
        ) : 'fc', true, true, e.target);
        e.target.dispatchEvent(ce);
        m = 0
      },
      touchcancel: (e) => {
        m = 0
      }
    };
  for (l in f) document.addEventListener(l, f[l], false)

  //EXAMPLE OF USE
  /!*let h = (e) => {
    console.log(e.type, e);
  };
  document.body.addEventListener('fc', h, false); // 0-50ms vs 500ms with normal click
  document.body.addEventListener('swl', h, false);
  document.body.addEventListener('swr', h, false);
  document.body.addEventListener('swu', h, false);
  document.body.addEventListener('swd', h, false);*!/
};
*/

/*window.nouniq = (arr, size = 2) => {
  let len = arr.length, i, out = [], counts = {};

  for (i = 0; i < len; i++) {
    let item = arr[i];
    counts[item] = counts[item] >= 1 ? counts[item] + 1 : 1;
    if (counts[item] >= size) {
      out.push(item);
    }
  }
  return out;
};*/

window.uniq = (arr) => {
  return arr.filter((item, pos) => {
    return arr.indexOf(item) === pos;
  });
};

/*window.uniq = (arr) => {
  return Array.from(new Set(arr));
};*/

/*window.arrDiff = (arrA, arrB) => {
  return arrA.map((a, i) => {
    return a - arrB[i]
  })
};*/

/*window.get_param = (name, url = window.location.href) => {
  //let params = new URLSearchParams(location.search);
  let params = (new URL(url)).searchParams;
  //params.getAll(name);
  return params.get(name);
};*/

/*window.get_param = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), decoded_url = decodeURIComponent(url), param;

  param = regex.exec(decoded_url);
  return !param || !param[2] ? false : sanitize(decodeURIComponent(param[2].replace(/\+/g, ' ')));
};*/

window.get_param = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), decoded_url = decodeURIComponent(url), param;
  try {
    param = regex.exec(decoded_url)[2].split('?');
    if (!param || !param[0]) return false;
  } catch (e) {
    return false;
  }
  return sanitize(decodeURIComponent(param[0].replace(/\+/g, ' ')));
};

window.diff = (value, search, comma = ",") => {
  let word, arr, result = false;
  word = '' + search.toLowerCase().split(comma) + '';
  arr = value.toLowerCase().split(comma);

  each(arr, (key, val) => {
    if (word.includes(val)) result = true;
  });
  return result;
};

/*
window.counter = (el, after = '') => {
  let count = {val: 0}, newVal = el.dataset.per || 100;
  return TweenLite.to(count, 5, {
    val: newVal, roundProps: 'val', onUpdate: update_val => {
      el.textContent = parseInt(count.val) + after;
    }
  });
};*/
