import {MarkerClusterer} from "@googlemaps/markerclusterer";

class search {
  constructor() {
    if (!this.setVars()) return
    this.setEvents()
  }

  setVars() {
    this.rel = document.body.querySelector('[data-gmap]')
    if(!this.rel) return false

    this.input = document.body.querySelector('[data-search_input]')
    if(!this.input) return false

    return true
  }

  setEvents() {
    window.initMap = this.initMap.bind(this)
    addJS('https://maps.googleapis.com/maps/api/js?key=AIzaSyAzv_umTBnIFfDO5oFy96YQUmHGaAXSgWI&callback=initMap&language=pl&loading=async&libraries=maps,marker,places&v=beta')
  }

  locations() {
    return [
      {lat: 52.1410808, lng: 21.0840602, price: 1400},
      {lat: 51.2416808, lng: 21.0640602, price: 1100},
      {lat: 52.1416808, lng: 21.0440602, price: 800},
      {lat: 52.3416808, lng: 21.0240602, price: 1400},
      {lat: 52.2916808, lng: 21.0840602, price: 2700},
      {lat: 51.2516808, lng: 20.9840602, price: 2100},
      {lat: 52.2449808, lng: 20.6840602, price: 2400}
    ];
  }

  async initMap() {
    const styledMapType = new google.maps.StyledMapType(
      [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ],
      {name: 'Styled Map'});

    const placeAutocomplete = new google.maps.places.PlaceAutocompleteElement()
    this.input.appendChild(placeAutocomplete)
    placeAutocomplete.addEventListener("gmp-placeselect", async ({ place }) => {
      await place.fetchFields({
        fields: ["displayName", "formattedAddress", "location"],
      })
      let data = place.toJSON()
      console.log(data.location.lat, data.location.lng)
      map.setCenter(new google.maps.LatLng(data.location.lat, data.location.lng))
      map.setZoom(14);
    });

    const mapOptions = {
      zoom: 10,
      center: {lat: 52.2916808, lng: 21.0840602},
      disableDefaultUI: true,
      gestureHandling: "cooperative",
      zoomControl: true,
      mapId: 'AIzaSyAzv_umTBnIFfDO5oFy96YQUmHGaAXSgWI',
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    }

    const map = new google.maps.Map(this.rel, mapOptions);
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
    const icon = this.rel.dataset.marker || 'img/marker.svg';

    const infoWindow = new google.maps.InfoWindow({
      content: '',
      disableAutoPan: true,
    });

    const markers = this.locations().map((position, i) => {
      const marker = new google.maps.Marker({
        position: position,
        icon: icon,
        label: {
          text: `${position.price} zł`,
          color: 'white',
          className: 'marker-position'
        }
      });

      marker.addListener("click", () => {
        infoWindow.setContent(`<strong>OSK Super</strong><br>Ul Wrocławska 10<br><p>Kursy od ${position.price} zł</p>`);
        infoWindow.open(map, marker);
      });
      return marker;
    })

    const markerCluster = new MarkerClusterer({markers, map})
  }

}

export default search
