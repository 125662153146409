import {_request, modal} from "../functions";

class api {
  constructor() {
    if (!this.setVars()) return false
    this.recaptcha = document.body.querySelector('[data-callback="submit"]')
    let recaptcha_private_key = '6LcNrxEoAAAAANxCmSYcIt8nGuEv03bDhnR9NPzo'
    this.recaptcha ? addJS(`https://www.google.com/recaptcha/api.js?render=${recaptcha_private_key}`, {
      id: '_captcha', async: true, defer: true, cb: () => {
        this.is_valid = false
        grecaptcha.ready(() => {
          grecaptcha.execute(`${recaptcha_private_key}`, {action: 'submit'}).then((response) => {
            _request('api/recaptcha', JSON.stringify({
              _token: csrf,
              response: response
            }), {
              cb: (xhr) => {
                xhr.json().then(json => {
                  switch (json.success) {
                    case true:
                      this.is_valid = true
                      break;
                  }
                })
                this.setEvents()
              }
            })

          })
        })
      }
    }) : this.setEvents()
  }

  setVars() {
    this.forms = document.body.querySelectorAll('form[data-api]')
    return this.forms.length
  }

  setEvents() {
    let data = {}, autoSubmit;

    each(this.forms, (key, val) => {
      val.addEventListener('submit', (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!this.is_valid && this.recaptcha) return this.captchaError()
        if (!val.length) return
        if (!val.dataset.api) return

        if (this.validateForm(val)) return modal({'status': false, 'msg': 'Uzupełnij wymagane pola'})
        if (!this.orderData._token) this.orderData._token = csrf

        if (!this.is_valid && this.recaptcha) return this.captchaError()

        this.orderData.paymentType = this.paymentType || false
        this.paymentType = false

        _request(val.dataset.api, JSON.stringify(this.orderData), {
          cb: (xhr) => {
            xhr.json().then(response => {
              val.reset();
              response.msg ? modal(response, () => {
                this.redirect(response)
              }) : this.redirect(response)
            })
          }
        });
      })

      autoSubmit = val.querySelectorAll('[data-submit="auto"]')
      autoSubmit.length && each(autoSubmit, (i, field) => {
        field.addEventListener('change', () => {
          try {
            val[val.length - 1].click()
          } catch (e) {
            console.error('invalid submit button')
          }
        })
      })
    })
  }

  resetReCaptcha(valid = false) {
    this.is_valid = valid;
    grecaptcha && grecaptcha.reset()
  }

  captchaError(callback) {
    return modal({msg: 'Nie udało się zweryfikować reCaptcha,<br>Spróbuj ponownie.'}, {
      cb: () => {
        if (typeof callback === 'function') callback()
      }
    })
  }

  redirect(response) {
    if (response.reload) return window.location.href = window.location.href
    if (!response.url) return;
    window.location.href = response.url
  }

  validateForm(form) {
    this.orderData = {}
    let inputs = form.querySelectorAll('input,select,textarea'), minLength = 0, error = []
    if (!inputs.length) return;

    each(inputs, (key, val) => {
      minLength = parseInt(val.dataset.min) || 0
      switch (val.type) {
        case 'checkbox':
          if (val.dataset.req === '1' && val.checked) {
            val.parentNode.classList.remove('error')
          } else if (val.dataset.req === '1' && !val.checked) {
            val.parentNode.classList.add('error')
            error.push(val.name)
          }
          this.orderData[val.name] = val.checked
          break;
        case 'email':
          if (is_email(val.value)) {
            val.parentNode.classList.remove('error')
            this.orderData[val.name] = val.value.toLowerCase()
          } else {
            val.parentNode.classList.add('error')
            error.push(val.name)
          }
          break;
        default:
          if (val.value.length >= minLength) {
            val.parentNode.classList.remove('error')
            this.orderData[val.name] = val.value
          } else {
            val.parentNode.classList.add('error')
            error.push(val.name)
          }
      }
    })
    return error.length
  }
}

export default api
