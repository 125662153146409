export async function _request(url, cfg = {}) {
  let controller = new AbortController(), obj = Object.assign({
    signal: controller.signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json' //'Content-Type': 'application/json', multipart/form-data, application/json, application/x-www-form-urlencoded
    },
  }, cfg)

  obj.headers['Content-Type'] === 'multipart/form-data' && delete(obj.headers['Content-Type'])

  const response = await fetch(url, obj)
  response.status !== 200 && controller.abort()

  switch(obj.type) {
    case 'text':
      return response.text()
    default:
      return response.json()
  }
}

export function modal(response = '', cb) {
  let modal = render(document.body, `<div id="modal" class="row modal h100 fix top0 left0 flex align-center justify-center" style="z-index:900">
  <div class="shad ${!response.status ? 'alert' : ''}" style="max-width:470px;width:100%;padding:2em;border-radius:15px;background-color:#fff">
    <h6 style="font-size:1em;font-weight:300">${response.msg}</h6>
    <button class="btn btn--sm mt30">OK</button>
  </div>
</div>`)
  let btn = modal.getElementsByTagName('button')[0]
  btn && btn.addEventListener('click', () => {
    del(modal)
    if (typeof cb === 'function') cb()
  })
  return modal
}
