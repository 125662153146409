import '../sass/core.scss'
import '../sass/framework/hd.scss'

import "./global"
import {lazyCSS} from "./class/lazyCSS"
import {lazyImg} from "./class/lazyImg"
import search from "./class/search"
import api from "./class/api";

const mod = {};

// ======== load core =========
mod.core = () => {
  mod.css = new lazyCSS()
  mod.lazy = new lazyImg('[data-url]')
  mod.api = new api()
  mod.search = new search()
}

document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', mod.core) : mod.core()

// Advanced menu

let moreOption = document.getElementById("moreOption")

if(moreOption){

moreOption.addEventListener("click", showAdvencedMenu)

function showAdvencedMenu() {
  let AdvencedMenu = document.getElementById("advanceSearch")
  let rotate = document.getElementById("arrow")

  AdvencedMenu.classList.toggle("show")
  rotate.classList.toggle("rotate")
}

}

// Menu

let toggleMenu = document.getElementById("btnToggle")

if(toggleMenu){

  toggleMenu.addEventListener("click", showMenu)

  function showMenu() {
    let menu = document.getElementById("menu")
    let height = document.getElementById("navbar")
    menu.classList.toggle("show-menu")
    height.classList.toggle("add-height")
  }

}
